(function () {
	'use strict';

	angular
		.module('app')
		.factory('dataStore', [
			'$q',
			'seedcodeCalendar',
			'firebaseIO',
			'utilities',
			'environment',
			dataStore,
		]);

	function dataStore(
		$q,
		seedcodeCalendar,
		firebaseIO,
		utilities,
		environment
	) {
		var localStorageAvailable = localStorageEnabled();

		var oldState;

		var dataStoreCache = {};

		//Vars for indexDB - Disabled because we currently aren't using indexDB for anything
		// var version = 3;
		// var db;
		// var supported = dataStoreSupported();
		// var openDeferred = openDatabase();
		return {
			getPermanentSavePath: getPermanentSavePath,
			initializeDataStoreCache: initializeDataStoreCache,

			initializeLocalSettings: initializeLocalSettings,

			saveState: saveState,
			getState: getState,
			clearState: clearState,
			clearAllSavedStates: clearAllSavedStates,

			setCookie: setCookie,
			parseCookie: parseCookie,
			getCookie: getCookie,

			removeCookie: removeCookie,
			removeAllCookies: removeAllCookies,

			// createTransaction: createTransaction,
			add: add,
			update: update,
			get: get,
			deleteDatabase: deleteDatabase,

			clearServiceWorkerCaches: clearServiceWorkerCaches,
		};

		function checkUseLocalSettings() {
			var useStaticData = seedcodeCalendar.get('useStaticData');
			var config = seedcodeCalendar.get('config');
			var settings = seedcodeCalendar.get('settings');
			var userSettings = seedcodeCalendar.get('userSettings');
			var useLocalSettings;

			if (
				useStaticData ||
				(userSettings && userSettings.useLocalSettings) ||
				(settings && settings.useLocalSettings) ||
				(config && config.useLocalSettings)
			) {
				useLocalSettings = true;
			}

			return useLocalSettings;
		}

		function checkForOldPrefix(name, sessionOnly, preventUserScope) {
			var userToken;
			if (name === 'userToken') {
				if (localStorageAvailable) {
					userToken = getStoredItem('dbk-state-' + name);
				} else {
					userToken = getCookie('dbk-state-' + name);
				}

				if (userToken) {
					oldState = {};
					oldState[name] = {
						name: name,
						sessionOnly: sessionOnly,
						preventUserScope: preventUserScope,
					};
					if (userToken) {
						window.setTimeout(function () {
							console.log('state', oldState);
						}, 5000);
					}
				}
			} else if (oldState) {
				oldState[name] = {
					name: name,
					sessionOnly: sessionOnly,
					preventUserScope: preventUserScope,
				};
			}
			return oldState;
		}

		function getDBKPrefix() {
			return 'dbk-state-';
		}

		function getDBKGlobalPrefix() {
			return 'global-';
		}

		function getStatePrefix(preventUserScope, oldStateExists) {
			var uid = seedcodeCalendar.get('uid');
			var userIDString =
				uid && !preventUserScope ? uid + '-' : getDBKGlobalPrefix();
			var prefixString = getDBKPrefix();

			return oldStateExists
				? getDBKPrefix()
				: prefixString + userIDString;
		}

		function getPermanentSavePath(oldPath) {
			var path = '';
			if (environment.isPhone) {
				path = 'phone';
			} else if (environment.isMobileDevice) {
				path = 'mobile';
			} else {
				path = 'desktop';
			}

			if (oldPath) {
				if (environment.isSalesforce) {
					path += '-salesforce';
				}
			} else {
				path += '-' + utilities.getDBKPlatform();
			}

			return path;
		}

		function saveStatePermanent(name, value, callback) {
			var path = getPermanentSavePath();
			// Update the data store cache with the new value
			if (dataStoreCache && name) {
				dataStoreCache[name] = value;
			}
			// Set permanent user setting in firebase
			firebaseIO.setUserSettingsData(
				null,
				path,
				name,
				value,
				false,
				callback
			);
		}

		//Used to migrate salesforce mobile users settings in old users location to new userSettings location
		function migrateFromOldPath(callback, fromLocalOnly) {
			var oldPath = getPermanentSavePath(true);
			var statePrefixRegex = new RegExp(getStatePrefix() + '(.*)');
			var localData = {};
			var match;

			if (
				environment.isSalesforce &&
				environment.isStandAlone &&
				!fromLocalOnly
			) {
				firebaseIO.getUserData(
					null,
					'settings/' + oldPath,
					saveToNewPath
				);
			} else {
				Object.keys(localStorage).forEach(function (key) {
					match = key.match(statePrefixRegex);
					if (match) {
						localData[match[1]] = localStorage[key];
					}
				});
				saveToNewPath(localData);
			}
			function saveToNewPath(data) {
				if (data) {
					dataStoreCache = data;
					Object.keys(data).forEach(function (key) {
						firebaseIO.setUserSettingsData(
							null,
							getPermanentSavePath(),
							key,
							data[key],
							false
						);
					});
				}
				callback();
			}
		}

		function initializeDataStoreCache(callback, isShare) {
			//Don't attempt to query firebase if in a share
			if (isShare) {
				dataStoreCache = {};
				callback();
				return;
			}

			firebaseIO.getUserSettingsData(
				null,
				getPermanentSavePath(),
				updateDataStoreCache
			);
			function updateDataStoreCache(data) {
				if (data) {
					dataStoreCache = data;
					callback();
				} else {
					migrateFromOldPath(callback);
				}
			}
		}

		function initializeLocalSettings(callback, isShare) {
			var migratedToLocal = getState(
				'migratedToLocal',
				false,
				false,
				true
			);
			var useLocalSettings = checkUseLocalSettings();

			if (isShare) {
				callback();
				return;
			}

			if (
				useLocalSettings &&
				dataStoreCache &&
				Object.keys(dataStoreCache).length &&
				!migratedToLocal
			) {
				// Migrate to local settings
				for (var property in dataStoreCache) {
					saveState(
						property,
						dataStoreCache[property],
						false,
						false,
						true
					);
				}
				saveState('migratedToLocal', true, false, false, true);
				callback();
			} else if (!useLocalSettings && migratedToLocal) {
				// Reset migratedToLocal
				clearState('migratedToLocal', false, false, false, true);
				migrateFromOldPath(callback, true);
			} else {
				callback();
			}
		}

		function saveState(
			name,
			value,
			sessionOnly,
			preventUserScope,
			localOnly
		) {
			var config = seedcodeCalendar.get('config');
			var useLocalSettings = checkUseLocalSettings();

			var statePrefix;

			//Exit if we are in a share so we don't affect save states on primary calendar accounts
			//Disable saving any new states when viewing a share
			if (config && (config.isShare || config.saveState === false)) {
				return;
			}

			if (localOnly || sessionOnly || useLocalSettings) {
				statePrefix = getStatePrefix(preventUserScope);
				if (localStorageAvailable) {
					if (sessionOnly) {
						setSessionItem(statePrefix + name, value);
					} else {
						setStoredItem(statePrefix + name, value);
					}
				} else {
					setCookie(statePrefix + name, value);
				}
			} else {
				//save to firebase
				saveStatePermanent(name, value);
			}
		}

		function getState(name, sessionOnly, preventUserScope, localOnly) {
			var useLocalSettings = checkUseLocalSettings();
			var oldStateCheck = checkForOldPrefix(
				name,
				sessionOnly,
				preventUserScope
			);
			var statePrefix;
			var value;

			if (localOnly || sessionOnly || useLocalSettings) {
				statePrefix = getStatePrefix(preventUserScope, oldStateCheck);
				if (localStorageAvailable) {
					value = sessionOnly
						? getSessionItem(statePrefix + name)
						: getStoredItem(statePrefix + name);
				} else {
					value = getCookie(statePrefix + name);
				}
			} else {
				value =
					name && dataStoreCache.hasOwnProperty(name)
						? dataStoreCache[name]
						: null;
			}

			if (oldStateCheck && value) {
				saveState(name, value, sessionOnly, preventUserScope);
				clearState(name, sessionOnly, preventUserScope, true);
			}
			return value;
		}

		function clearState(
			name,
			sessionOnly,
			preventUserScope,
			isOldState,
			localOnly
		) {
			var useLocalSettings = checkUseLocalSettings();
			var statePrefix;

			if (localOnly || sessionOnly || useLocalSettings) {
				statePrefix = getStatePrefix(preventUserScope, isOldState);
				if (localStorageAvailable) {
					if (sessionOnly) {
						removeSessionItem(statePrefix + name);
					} else {
						removeStoredItem(statePrefix + name);
					}
				} else {
					removeCookie(statePrefix + name);
				}
			} else {
				delete dataStoreCache[name];
				saveState(name, null, sessionOnly, preventUserScope);
			}
		}
		function clearAllSavedStates() {
			if (localStorageAvailable) {
				removeAllStoredItems();
				removeAllSessionItems();
			} else {
				removeStateCookies();
			}
			dataStoreCache = {};
			firebaseIO.setUserSettingsData(null, null, getPermanentSavePath());
		}

		function setCookie(name, value, days, onlyRootDomain) {
			var expires;
			var secure = location.protocol === 'https:' ? '; secure' : '';
			var domain = '';

			if (onlyRootDomain) {
				var host = window.location.host;
				host = host.split('.');
				domain += '.' + host[1];
				domain += '.' + host[2];

				domain = '; domain=' + domain;
			}

			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
				expires = '; expires=' + date.toGMTString();
			} else {
				expires = '';
			}
			document.cookie =
				name + '=' + value + expires + '; path=/' + domain + secure;
		}

		function parseCookie(name, cookieString) {
			var nameEQ = name + '=';
			var ca = cookieString.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0) {
					return c.substring(nameEQ.length, c.length);
				}
			}
			return null;
		}

		function getCookie(name) {
			var cookies = document.cookie;
			return parseCookie(name, cookies);
		}

		function removeCookie(name, onlyRootDomain) {
			setCookie(name, '', -1, onlyRootDomain);
		}

		function removeStateCookies() {
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				var spcook = cookies[i].split('=');
				if (spcook[0].indexOf('dbk-state') > -1) {
					removeCookie(spcook[0]);
				}
			}
		}

		function removeAllCookies(omitBeginsWith, onlyRootDomain) {
			//Begins with is a string to search if the cookie name begins with before deleting
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				var spcook = cookies[i].split('=');
				if (omitBeginsWith && spcook[0]) {
					var beginsWithLength = omitBeginsWith.length;
					if (
						spcook[0].substring(0, beginsWithLength) ===
						omitBeginsWith
					) {
						continue;
					}
				}
				removeCookie(spcook[0], onlyRootDomain);
			}
		}

		function localStorageEnabled() {
			var storageString = 'dbk-state-localstorage';
			try {
				//Attempt to set something to localstorage
				localStorage.setItem(storageString, storageString);
				localStorage.removeItem(storageString);
				return 'localStorage' in window && window.localStorage !== null;
			} catch (e) {
				return false;
			}
		}

		// Local Storage
		function setStoredItem(name, value) {
			localStorage.setItem(name, value);
		}

		function getStoredItem(name) {
			return localStorage.getItem(name);
		}

		function removeStoredItem(name) {
			localStorage.removeItem(name);
		}

		function removeAllStoredItems() {
			localStorage.clear();
		}

		// Session Storage (goes away when browser is quit)
		function setSessionItem(name, value) {
			sessionStorage.setItem(name, value);
		}

		function getSessionItem(name) {
			return sessionStorage.getItem(name);
		}

		function removeSessionItem(name) {
			sessionStorage.removeItem(name);
		}

		function removeAllSessionItems() {
			sessionStorage.clear();
		}

		// Index DB

		//Is indexDB supported
		function dataStoreSupported() {
			if ('indexedDB' in window) {
				return true;
			} else {
				return false;
			}
		}

		function openDatabase() {
			//We currently aren't using this yet so let's disable it until we can- ToDo: There may be issues with opening the indexedDB in some browsers on some servers
			if (!supported) {
				return;
			}
			var deferred = $q.defer();

			var openRequest = indexedDB.open('DayBack', version);
			openRequest.onupgradeneeded = function (e) {
				db = e.target.result;

				if (!db.objectStoreNames.contains('calendars')) {
					db.createObjectStore('calendars');
					// db.createObjectStore("calendars", { keyPath: "id" });
				}
				if (!db.objectStoreNames.contains('events')) {
					db.createObjectStore('events');
					// db.createObjectStore("events", { keyPath: "id" });
				}
				deferred.resolve(db);
			};
			openRequest.onsuccess = function (e) {
				db = e.target.result;
				deferred.resolve(db);
			};
			openRequest.onerror = function (e) {
				console.log('Error');
				console.dir(e);
				deferred.resolve(e);
			};
			return deferred;
		}

		// function createTransaction(name) {
		//   var transaction = db.transaction(["name"],"readwrite");
		//   var store = transaction.objectStore("name");
		//   return store;
		// }

		function add(transactionName, data, id, callback) {
			var openPromise = openDeferred.promise;
			// var request = store.add(data,id);
			openPromise.then(
				function (someData) {
					// console.log('My first promise succeeded', someData);
					var transaction = db.transaction(
						[transactionName],
						'readwrite'
					);
					var store = transaction.objectStore(transactionName);
					executeTransaction(store.add(data, id), callback);
				},
				function (error) {
					console.log('My first promise failed', error);
				}
			);
		}

		function update(transactionName, data, id, callback) {
			var openPromise = openDeferred.promise;
			// var request = store.add(data,id);
			openPromise.then(
				function (someData) {
					//console.log('My first promise succeeded', someData);
					var transaction = db.transaction(
						[transactionName],
						'readwrite'
					);
					var store = transaction.objectStore(transactionName);
					executeTransaction(store.put(data, id), callback);
				},
				function (error) {
					console.log('My first promise failed', error);
				}
			);
		}

		function get(transactionName, id, callback) {
			var openPromise = openDeferred.promise;
			// var request = store.add(data,id);
			openPromise.then(
				function (someData) {
					// console.log('My first promise succeeded', someData);
					var transaction = db.transaction(
						[transactionName],
						'readonly'
					);
					var store = transaction.objectStore(transactionName);
					executeTransaction(store.get(id), callback);
				},
				function (error) {
					console.log('My first promise failed', error);
				}
			);
		}

		function executeTransaction(request, callback) {
			request.onerror = function (e) {
				console.log('Error', e.target.error.name);
				//some type of error handler
			};

			request.onsuccess = function (e) {
				callback(e);
				// console.log("Woot! Did it");
			};
		}

		function deleteDatabase(name) {
			//Delete Database
			var req = indexedDB.deleteDatabase(name);
			req.onsuccess = function () {
				console.log('Deleted database successfully');
			};
			req.onerror = function () {
				console.log("Couldn't delete database");
			};
			req.onblocked = function () {
				console.log(
					"Couldn't delete database due to the operation being blocked"
				);
			};
		}
	}

	/** @type{() => Promise} */
	async function clearServiceWorkerCaches() {
		/** @type {Promise} */
		let cachePromise;
		/** @type {Promise} */
		let registrationsPromise;

		let cachesCleared = false;

		// Remove service worker before switching to beta mode to prevent any cache loading
		if ('caches' in window) {
			cachePromise = caches.keys();
		}
		if ('serviceWorker' in navigator) {
			registrationsPromise = navigator.serviceWorker.getRegistrations();
		}

		try {
			const [cacheNames, registrations] = await Promise.all([
				cachePromise,
				registrationsPromise,
			]);

			// Clear caches
			if (cacheNames) {
				cacheNames.forEach((/** @type {string} */ cacheName) => {
					caches.delete(cacheName);
					cachesCleared = true;
				});
			}
			// Unregister service workers
			if (registrations) {
				registrations.forEach(
					(/** @type {ServiceWorkerRegistration} */ registration) => {
						registration.unregister();
					}
				);
			}
			return cachesCleared;
		} catch (err) {
			throw new Error('Error: could not clear service worker cache');
		}
	}
})();
